import fetch from "../interceptor/fetchInterceptor";

const AdminService = {};

AdminService.UpdatePassword = function (data) {
  return fetch({
    url: "/auth/updatePassword",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.GetProfile = function () {
  return fetch({
    url: "/detail/admin",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.UpdateProfile = function (data) {
  return fetch({
    url: "/update/admin",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

AdminService.GetMainServices = function () {
  return fetch({
    url: "/admin/services",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.ReorderMainServices = function (data) {
  return fetch({
    url: "/admin/services/reorder",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
};

AdminService.AddMainService = function (data) {
  return fetch({
    url: "/admin/service/add",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

AdminService.UpdateMainService = function (data, id) {
  return fetch({
    url: `/admin/service/edit/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

AdminService.DeleteMainService = function (id) {
  return fetch({
    url: `/admin/service/${id}`,
    method: "delete",
    headers: {
      "public-request": "true",
    },
  });
};

// AdminService.GetSubServices = function (id, status) {
//   return fetch({
//     url: `/admin/service/sub-service/${id}/${status}`,
//     method: "get",
//     headers: {
//       "public-request": "true",
//     },
//   });
// };

AdminService.GetAllSubServices = function (id, status) {
  return fetch({
    url: `/admin/service/sub-service/${id}/${status}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.AddSubService = function (data) {
  return fetch({
    url: "/admin/service/sub-service/add",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

AdminService.UpdateSubService = function (data, id) {
  return fetch({
    url: `/admin/service/sub-service/edit/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

AdminService.DeleteSubService = function (id) {
  return fetch({
    url: `/admin/service/sub-service/${id}`,
    method: "delete",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.GetPmList = function (filter) {
  return fetch({
    url: `/admin/list/property-manager/${filter}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.UpdatePropertyManager = function (id, data) {
  return fetch({
    url: `/admin/property-manager/update-profile/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: data
  });
};

AdminService.UpdatePropertyManagerPassword = function (id, data) {
  return fetch({
    url: `/admin/property-manager/update-password/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
};
AdminService.GetPropertyManagerById = function (id) {
  return fetch({
    url: `/admin/detail/property-manager/${id}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.UpdatePmStatus = function (data) {
  return fetch({
    url: `/admin/change-status/property-manager`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.GetPropertyList = function (filter) {
  return fetch({
    url: `/admin/property/list/${filter}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.GetPropertyById = function (id) {
  return fetch({
    url: `/admin/property/${id}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.UpdatePropertyStatus = function (id, data) {
  return fetch({
    url: `/admin/property/change-status/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  })
}

AdminService.GetPropertyListByPmId = function (id) {
  return fetch({
    url: `/admin/property-manager/property-list/${id}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.GetSubscriptionList = function (id) {
  return fetch({
    url: `/admin/subscription/list`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.UpdatePmSubscriptionPlan = function (id, data) {
  return fetch({
    url: `/admin/property-manager/update-subscription/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.GetAllServicesCategory = function (service_id, status) {
  return fetch({
    url: `/admin/service/categories/${service_id}/${status}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.AddServiceCategory = function (data) {
  return fetch({
    url: "/admin/service/category/add",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.UpdateServiceCategory = function (data, id) {
  return fetch({
    url: `/admin/service/category/edit/${id}`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.DeleteServiceCategory = function (id) {
  return fetch({
    url: `/admin/service/category/${id}`,
    method: "delete",
    headers: {
      "public-request": "true",
    },
  });
};


AdminService.GetMainServicesByProperty = function (id) {
  return fetch({
    url: `/admin/property/services/${id}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.GetSubServicesByProperty = function (pid, sid) {
  return fetch({
    url: `/admin/property/services/sub-service/${pid}/${sid}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.GetAttachedSubServicesByProperty = function (pid, sid) {
  return fetch({
    url: `/admin/property/attached/services/${pid}/${sid}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.AttachSubServiceToProperty = function (data) {
  return fetch({
    url: `/admin/property/services/sub-service/attach`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.DettachSubServiceToProperty = function (data) {
  return fetch({
    url: `/admin/property/services/sub-service/detach`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.UpdatePmDescForSubServiceToProperty = function (data) {
  return fetch({
    url: `/admin/property/services/sub-service/update-pm-desc`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.GetAllServicesCategory = function (service_id, status) {
  return fetch({
    url: `/admin/service/categories/${service_id}/${status}`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
};

AdminService.GetOrderList = function (data) {
  return fetch({
    url: `/admin/order/list`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
};

AdminService.GetOrderAnalytics = function (data) {
  return fetch({
    url: `/admin/order/analytics`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
};

AdminService.UpdateOrderStatus = function (data) {
  return fetch({
    url: `/admin/order/update-status`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.GetConfig = function () {
  return fetch({
    url: `/config/list`,
    method: "get",
    headers: {
      "public-request": "true",
    }
  });
};

AdminService.UpdateConfig = function (data) {
  return fetch({
    url: `/config/update`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AdminService.getGuestInfo = function (id) {
  return fetch({
    url: `/admin/guest-info/list/` + id,
    method: "get",
    headers: {
      "public-request": "true",
    }
  });
};

AdminService.getBookings = function () {
  return fetch({
    url: `/property-manager/booking-id/list`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

AdminService.getDashboard = function () {
  return fetch({
    url: `/admin/dashboard`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

AdminService.getOrderStatusCountList = function (data) {
  return fetch({
    url: `/admin/order-status-count`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
}

AdminService.getLastTwelveMonthsRevenueWithoutFee = function (data) {
  return fetch({
    url: `/admin/revenue-without-fee`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
}

AdminService.getLastTwelveMonthsRevenueWithFee = function (data) {
  return fetch({
    url: `/admin/revenue-with-fee`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
}

AdminService.totalEarningsByService = function (data) {
  return fetch({
    url: `/admin/revenue-by-services`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
}

AdminService.getMonthlyRevenue = function (data) {
  return fetch({
    url: `/admin/monthly-revenue`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
}

AdminService.getTopPMMonthlyRevenue = function (data) {
  return fetch({
    url: `/admin/top-pm-revenue`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
}

AdminService.totalRevenue = function (data) {
  return fetch({
    url: `/admin/total-revenue`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
}

AdminService.totalRevenueWithFee = function (data) {
  return fetch({
    url: `/admin/total-revenue-with-fee`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data
  });
}

AdminService.GetUserBookingDetails = function () {
  return fetch({
    url: `/admin/user-booking-details`,
    method: "get",
    headers: {
      "public-request": "true",
    }
  });
}

AdminService.GetFilterUserBooking = function (passData) {
  return fetch({
    url: `/admin/booking-filter`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: passData
  });
}

export default AdminService;
